import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';

const MobileCategoriesRefinement = connectHierarchicalMenu(
  ({ items, refine }) => {
    const {
      categories: { edges: categories },
    } = useStaticQuery(graphql`
      query {
        categories: allCategory {
          edges {
            node {
              name
              displayName
              slug
            }
          }
        }
      }
    `);

    const categoriesNameMap = useMemo(
      () =>
        new Map(categories.map(({ node }) => [node.slug, node.displayName])),
      [categories]
    );

    function renderItems(subItems: any[], level = 0, parentPath = '') {
      const itemWithChildren = subItems.find(
        (item) => item.isRefined && item.items?.length
      );
      if (itemWithChildren) {
        return renderItems(
          itemWithChildren.items,
          level + 1,
          `${parentPath}${itemWithChildren.label}_`
        );
      }

      return (
        <>
          {subItems
            .sort((s1, s2) => (s2.count ?? 0) - (s1.count ?? 0))
            .map((item) => (
              <Fragment key={item.value || `refined-${item.label}`}>
                <button
                  className={clsx(
                    'border rounded-md border-gray-300 p-2 flex flex-col whitespace-nowrap text-left',
                    item.isRefined
                      ? 'text-white bg-primary-300'
                      : 'text-primary-300 bg-white'
                  )}
                  type="button"
                  onClick={() => refine(item.value)}
                >
                  <span className="font-semibold">
                    {categoriesNameMap.get(`${parentPath}${item.label}`)}
                  </span>
                  <span className="opacity-60 text-xs">
                    ({item.count}) produse
                  </span>
                </button>
              </Fragment>
            ))}
        </>
      );
    }

    return <div className="flex flex-row gap-4 px-4">{renderItems(items)}</div>;
  }
);

export default MobileCategoriesRefinement;

import Modal from '@/components/Modal';
import { ModalControl } from '@/lib/modal';
import clsx from 'clsx';
import React, { FC, Fragment } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';

const MobileSortBy = connectSortBy(({ items, refine, closeModal }) => {
  return (
    <div className="-mt-3">
      {items.map((item, idx, self) => (
        <Fragment key={item.value}>
          <button
            className={clsx(
              item.isRefined && 'font-semibold',
              'py-3 w-full text-left'
            )}
            type="button"
            onClick={() => {
              refine(item.value);
              closeModal();
            }}
          >
            {item.label}
          </button>
          {idx < self.length - 1 && <hr className="border-gray-250 -mx-4" />}
        </Fragment>
      ))}
    </div>
  );
});

type MobileSortModalProps = {
  control: ModalControl;
  index: string;
};

const MobileSortModal: FC<MobileSortModalProps> = ({ control, index }) => {
  return (
    <>
      <Modal control={control} title="Ordoneaza" unmount={false}>
        <MobileSortBy
          defaultRefinement={index}
          items={[
            { value: `${index}`, label: 'Relevanta' },
            { value: `${index}_price_asc`, label: 'Pret crescator' },
            {
              value: `${index}_price_desc`,
              label: 'Pret descrescator',
            },
          ]}
          closeModal={control.close}
        />
      </Modal>
    </>
  );
};

export default MobileSortModal;

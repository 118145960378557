import { useIsMobile } from '@/lib';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import { FC } from 'react';

type ExplanatoryBannerProps = {
  className?: string;
};

const ExplanatoryBanner: FC<ExplanatoryBannerProps> = () => {
  const isMobile = useIsMobile();

  const { bgImage, bgImageMobile, fgImage } = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "fso/kitchen-background.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      bgImageMobile: file(
        relativePath: { eq: "fso/mobile-kitchen-background.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      fgImage: file(
        relativePath: { eq: "fso/transparent-pricing-foreground.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      className="h-20 lg:h-36"
      style={{
        backgroundPosition: isMobile ? 'center' : '100% 55%, center',
        backgroundSize: isMobile ? 'cover' : '325px, cover',
      }}
      {...convertToBgImage(
        isMobile
          ? getImage(bgImageMobile)
          : [getImage(fgImage), getImage(bgImage)]
      )}
    >
      <div className="container m-auto h-full">
        <div className="flex flex-col items-center lg:items-start justify-center h-full max-w-3xl px-4 lg:px-12">
          <div className="font-semibold lg:font-bold text-primary-300 lg:text-2xl text-center lg:text-left">
            Cere oferte fara obligatii de la mai multi furnizori si primesti
            raspunsul in maxim 24h.
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default ExplanatoryBanner;

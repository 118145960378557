import Modal from '@/components/Modal';
import PriceRefinement from '@/components/product/PriceRefinement';
import SpecificationRefinement from '@/components/product/SpecificationRefinement';
import { ALL_SPECIFICATIONS } from '@/gatsby/specifications';
import { ModalControl } from '@/lib/modal';
import clsx from 'clsx';
import React, { FC } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return (
    <span>
      Vezi {hitCount} rezultat{hitCount !== 1 ? 'e' : ''}
    </span>
  );
});

type MobileFiltersModalProps = {
  control: ModalControl;
  currentCategorySlug?: string;
  clearFilters: () => void;
};

const MobileFiltersModal: FC<MobileFiltersModalProps> = ({
  control,
  currentCategorySlug,
  clearFilters,
}) => {
  return (
    <>
      <Modal
        control={control}
        title="Filtreaza"
        unmount={false}
        renderActions={(props) => (
          <>
            <button
              {...props}
              className={clsx(
                props.className,
                'bg-primary text-white font-semibold'
              )}
              type="button"
              onClick={control.close}
            >
              <HitCount />
            </button>
            <button
              {...props}
              className={clsx(props.className, 'ring ring-gray-250 opacity-60')}
              type="button"
              onClick={() => {
                clearFilters();
                control.close();
              }}
            >
              Sterge filtre
            </button>
          </>
        )}
      >
        <PriceRefinement attribute="price.value" className="mt-4" />

        {(currentCategorySlug?.indexOf('_') ?? -1) > -1 && (
          <>
            {ALL_SPECIFICATIONS.map((spec) => (
              <SpecificationRefinement
                key={spec}
                attribute={spec}
                specLabel={spec}
                limit={4}
                showMore
                showMoreLimit={30}
              />
            ))}
          </>
        )}
      </Modal>
    </>
  );
};

export default MobileFiltersModal;

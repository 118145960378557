import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import CategoriesRefinement from '@/components/product/CategoriesRefinement';
import ExplanatoryBanner from '@/components/product/ExplanatoryBanner';
import MobileCategoriesRefinement from '@/components/product/MobileCategoriesRefinement';
import MobileFiltersModal from '@/components/product/MobileFiltersModal';
import MobileSortModal from '@/components/product/MobileSortModal';
import PriceRefinement from '@/components/product/PriceRefinement';
import SearchBox from '@/components/product/SearchBox';
import SearchResult from '@/components/product/SearchResult';
import SpecificationRefinement from '@/components/product/SpecificationRefinement';
import { ALL_SPECIFICATIONS } from '@/gatsby/specifications';
import { slugify, useIsMobile } from '@/lib';
import { useModal } from '@/lib/modal';
import {
  ChevronRightIcon,
  HomeIcon,
  SortDescendingIcon,
} from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import algoliaSearch from 'algoliasearch/lite';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, InstantSearch, SortBy } from 'react-instantsearch-dom';
import { StringParam, useQueryParam } from 'use-query-params';

const searchClient = algoliaSearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
const DEFAULT_INDEX = process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX_NAME;

const ProductsSearchPage: FC<PageProps> = () => {
  const [query, setQuery] = useState('');
  const [savedQuery, setSavedQuery] = useQueryParam('q', StringParam);
  const [currentCategorySlug, setCurrentCategorySlug] = useQueryParam(
    'category',
    StringParam
  );
  const [searchState, setSearchState] = useState<any>({});
  const debouncedSetStateRef = useRef<number>();
  const isMobile = useIsMobile();
  const mobileFiltersModal = useModal();
  const mobileSortModal = useModal();

  useEffect(() => {
    if (savedQuery) setQuery(savedQuery);

    return () => {
      if (debouncedSetStateRef.current)
        clearTimeout(debouncedSetStateRef.current);
    };
  }, [debouncedSetStateRef]);

  const onSearchStateChange = ({
    query: newQuery,
    hierarchicalMenu,
    ...rest
  }) => {
    // saved query
    if (debouncedSetStateRef.current)
      clearTimeout(debouncedSetStateRef.current);
    debouncedSetStateRef.current = window?.setTimeout(() => {
      if (savedQuery !== newQuery) {
        setSavedQuery(newQuery);
      }
    }, 400);

    // query
    setQuery(newQuery);
    if (query !== newQuery) {
      setCurrentCategorySlug(undefined);
      setSearchState({});
    } else {
      // category
      const newCategorySlug =
        hierarchicalMenu?.['categories.lvl0']?.replace(/ > /g, '_') ??
        undefined;
      if (currentCategorySlug !== newCategorySlug) {
        setCurrentCategorySlug(newCategorySlug);
      }

      // everything else
      setSearchState(rest);
    }
  };

  const {
    categories: { edges: categories },
  } = useStaticQuery(graphql`
    fragment CategoryOnSearchPage on Category {
      name
      qualifiedName
      displayName
      slug
      breadcrumbs {
        name
        qualifiedName
        displayName
        slug
      }
    }

    query {
      categories: allCategory(
        filter: { qualifiedName: { regex: "/^[^_]*$/" } }
      ) {
        edges {
          node {
            ...CategoryOnSearchPage
            subCategories {
              ...CategoryOnSearchPage
              subCategories {
                ...CategoryOnSearchPage
                subCategories {
                  ...CategoryOnSearchPage
                }
              }
            }
          }
        }
      }
    }
  `);
  const currentCategory = useMemo((): any | null => {
    if (!currentCategorySlug) {
      return null;
    }
    const path = currentCategorySlug.split('_');
    let selectedCategory;
    while (path.length) {
      const currentName = path.splice(0, 1)[0];
      selectedCategory = (
        selectedCategory?.subCategories || categories.map(({ node }) => node)
      ).find((it) => slugify(it.name) === currentName);
    }
    return selectedCategory;
  }, [currentCategorySlug]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={DEFAULT_INDEX}
      stalledSearchDelay={500}
      searchState={{
        ...searchState,
        query,
        hierarchicalMenu: currentCategorySlug
          ? {
              'categories.lvl0': currentCategorySlug.replace(/_/g, ' > '),
            }
          : undefined,
      }}
      onSearchStateChange={onSearchStateChange}
    >
      <Configure clickAnalytics />
      <Layout
        className="relative"
        fsoHeader
        fsoHeaderOverrideSearchForm={<SearchBox />}
        minimalFooter
      >
        <SEO
          url="ro/cauta-produse"
          title={`Cauta ${
            currentCategory?.displayName ?? 'echipamente'
          } HoReCa pentru afacerea ta`}
          description=""
          lang="ro"
        />

        <ExplanatoryBanner />

        <section className="min-h-screen flex flex-col ">
          <section className="hidden lg:flex container m-auto w-full p-4 items-center gap-2 pb-4 text-gray-500 opacity-60">
            <button
              type="button"
              onClick={() => setCurrentCategorySlug(undefined)}
            >
              <HomeIcon className="h-6" />
            </button>
            {currentCategory &&
              [...currentCategory.breadcrumbs, currentCategory].map(
                (parentCategory) => (
                  <Fragment key={parentCategory.qualifiedName}>
                    <ChevronRightIcon className="h-3" />
                    <button
                      type="button"
                      onClick={() =>
                        setCurrentCategorySlug(parentCategory.slug)
                      }
                    >
                      {parentCategory.displayName}
                    </button>
                  </Fragment>
                )
              )}
            <div className="flex-1" />
            {!isMobile && (
              <div className="flex gap-2">
                <div className="text-gray-500 opacity-60">Sorteaza dupa:</div>
                <SortBy
                  defaultRefinement={DEFAULT_INDEX}
                  items={[
                    { value: `${DEFAULT_INDEX}`, label: 'Relevanta' },
                    {
                      value: `${DEFAULT_INDEX}_price_asc`,
                      label: 'Pret crescator',
                    },
                    {
                      value: `${DEFAULT_INDEX}_price_desc`,
                      label: 'Pret descrescator',
                    },
                  ]}
                />
              </div>
            )}
          </section>

          <section className="container m-auto w-full min-h-screen flex">
            {!isMobile && (
              <div className="hidden lg:block p-4 min-w-80 max-w-sm">
                <CategoriesRefinement
                  attributes={[
                    'categories.lvl0',
                    'categories.lvl1',
                    'categories.lvl2',
                    'categories.lvl3',
                    'categories.lvl4',
                  ]}
                  limit={20}
                />

                <PriceRefinement attribute="price.value" className="mt-4" />
                <SpecificationRefinement
                  attribute="soldBy"
                  specLabel="Vandut de"
                  limit={4}
                  showMore
                  showMoreLimit={30}
                  className="mt-4"
                />

                {(currentCategorySlug?.indexOf('_') ?? -1) > -1 && (
                  <>
                    {ALL_SPECIFICATIONS.map((spec) => (
                      <SpecificationRefinement
                        key={spec}
                        attribute={spec}
                        specLabel={spec}
                        limit={4}
                        showMore
                        showMoreLimit={30}
                      />
                    ))}
                  </>
                )}
              </div>
            )}

            <SearchResult
              className="flex-1 bg-gray-200"
              currentCategoryName={currentCategory?.displayName}
              hitType="product"
            >
              {isMobile && (
                <>
                  <div className="overflow-y-auto -mx-4 max-w-screen pb-4">
                    <MobileCategoriesRefinement
                      attributes={[
                        'categories.lvl0',
                        'categories.lvl1',
                        'categories.lvl2',
                        'categories.lvl3',
                        'categories.lvl4',
                      ]}
                    />
                  </div>
                  <hr className="-mx-4 mb-4 border-gray-300 border-opacity-60" />
                  <div className="flex gap-2 pb-4">
                    <button
                      className="border rounded-md border-gray-400 bg-white p-2 flex-1 text-primary-300 flex items-center justify-center"
                      type="button"
                      onClick={() => mobileFiltersModal.open()}
                    >
                      <FilterIcon className="h-5 w-5 mr-2" />
                      Filtreaza
                    </button>
                    <button
                      className="border rounded-md border-gray-400 bg-white p-2 flex-1 text-primary-300 flex items-center justify-center"
                      type="button"
                      onClick={() => mobileSortModal.open()}
                    >
                      <SortDescendingIcon className="h-5 w-5 mr-2" />
                      Ordoneaza
                    </button>
                  </div>
                </>
              )}
            </SearchResult>
          </section>
        </section>
      </Layout>

      {isMobile && (
        <>
          <MobileFiltersModal
            control={mobileFiltersModal}
            currentCategorySlug={currentCategorySlug}
            clearFilters={() => setSearchState({})}
          />
          <MobileSortModal control={mobileSortModal} index={DEFAULT_INDEX} />
        </>
      )}
    </InstantSearch>
  );
};

export default ProductsSearchPage;

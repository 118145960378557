import TextInput from '@/components/form/TextInput';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { connectRange } from 'react-instantsearch-dom';
import { getTrackBackground, Range } from 'react-range';

type PriceRefinementProps = {
  className?: string;
} & any;

const PriceRefinement: FC<PriceRefinementProps> = connectRange(
  ({ className, min, max, currentRefinement, canRefine, refine }) => {
    const [stateMin, setStateMin] = useState(min ?? 0);
    const [stateMax, setStateMax] = useState(max ?? 1);

    useEffect(() => {
      if (canRefine) {
        setStateMin(currentRefinement.min);
        setStateMax(currentRefinement.max);
      }
    }, [currentRefinement.min, currentRefinement.max]);

    if (Number.isNaN(min) || !max || min >= max) {
      return null;
    }
    if (stateMin < min || stateMin > max) {
      setStateMin(min);
      return null;
    }
    if (stateMax < min || stateMax > max) {
      setStateMax(max);
      return null;
    }
    if (stateMin > stateMax) {
      setStateMin(stateMax);
      setStateMax(stateMin);
      return null;
    }

    const onFinalChange = () => {
      if (
        currentRefinement.min !== stateMin ||
        currentRefinement.max !== stateMax
      ) {
        refine({ min: stateMin, max: stateMax });
      }
    };

    const onChange = ([newMin, newMax]: number[]) => {
      setStateMin(newMin ?? min);
      setStateMax(newMax ?? max);
    };

    return (
      <div className={clsx(className)}>
        <div className="font-semibold text-base text-primary-300 mb-2">
          Pret
        </div>

        <div className="px-1.5">
          <Range
            min={min}
            max={max}
            step={1}
            values={[stateMin, stateMax]}
            allowOverlap
            onChange={onChange}
            onFinalChange={onFinalChange}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={props.style}
                className="w-full h-3 flex"
                role="slider"
                aria-valuenow={currentRefinement.min}
                tabIndex={0}
              >
                <div
                  ref={props.ref}
                  className="bg-white w-full h-1 self-center"
                  style={{
                    background: getTrackBackground({
                      values: [stateMin, stateMax],
                      colors: ['#EBE6E3', '#0F5C52', '#EBE6E3'],
                      min,
                      max,
                    }),
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={props.style}
                className="rounded-full bg-white border-2 border-primary h-3 w-3"
              />
            )}
          />
        </div>

        <div className="flex items-center mt-2">
          <TextInput
            className="flex-1 w-28"
            formik={
              {
                touched: {},
                errors: {},
                getFieldProps: () => ({
                  value: stateMin,
                  onChange: ({ target: { value } }) => setStateMin(value),
                  onBlur: onFinalChange,
                }),
              } as any
            }
            startPiece={<CurrencyEuroIcon className="-ml-2 h-5 w-5" />}
            name="min"
            type="number"
          />
          <div className="mx-2">-</div>
          <TextInput
            className="flex-1 w-28"
            formik={
              {
                touched: {},
                errors: {},
                getFieldProps: () => ({
                  value: stateMax,
                  onChange: ({ target: { value } }) => setStateMax(value),
                  onBlur: onFinalChange,
                }),
              } as any
            }
            startPiece={<CurrencyEuroIcon className="-ml-2 h-5 w-5" />}
            name="max"
            type="number"
          />
        </div>
      </div>
    );
  }
);

export default PriceRefinement;

import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';

const CategoriesRefinement = connectHierarchicalMenu(({ items, refine }) => {
  const {
    categories: { edges: categories },
  } = useStaticQuery(graphql`
    query {
      categories: allCategory {
        edges {
          node {
            name
            displayName
            slug
          }
        }
      }
    }
  `);

  const categoriesNameMap = useMemo(
    () => new Map(categories.map(({ node }) => [node.slug, node.displayName])),
    [categories]
  );

  function renderItems(subItems: any[], level = 0, parentPath = '') {
    const refinedItem = subItems.find((it) => it.isRefined);
    const itemsToRender = refinedItem ? [refinedItem] : subItems;
    return (
      <>
        {itemsToRender
          .sort((s1, s2) => (s2.count ?? 0) - (s1.count ?? 0))
          .map((item) => (
            <Fragment key={item.value || `refined-${item.label}`}>
              <button
                className={clsx(
                  'text-gray-500 text-left flex items-center justify-between py-1',
                  item.isRefined && 'font-semibold'
                )}
                style={{ marginLeft: `${(level + 1) * 0.5}rem` }}
                type="button"
                onClick={() => refine(item.value)}
              >
                <span>
                  {categoriesNameMap.get(`${parentPath}${item.label}`)}
                </span>
                {!item.isRefined && (
                  <span className="ml-4 opacity-60">{item.count}</span>
                )}
              </button>
              {!!item.items?.length &&
                renderItems(
                  item.items,
                  level + 1,
                  `${parentPath}${item.label}_`
                )}
            </Fragment>
          ))}
      </>
    );
  }

  return (
    <div className="flex flex-col">
      <button
        className="font-semibold text-primary-300 text-left"
        type="button"
        onClick={() => refine(undefined)}
      >
        Categorii
      </button>
      {renderItems(items)}
    </div>
  );
});

export default CategoriesRefinement;

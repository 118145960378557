export const ALL_SPECIFICATIONS = [
  'Producator',
  'Putere',
  'Alimentare',
  'Temperatura',
  'Capacitate',
  'Capacitate tavi',
  'Alimentare plita',
  'Alimentare cuptor',
  'Putere plita',
  'Putere cuptor',
  'Numar ochiuri',
  'Configuratie ochiuri',
  'Capacitate pizza/nivel',
  'Diametru pizza',
  'Tip panou',
  'Tip umiditate',
  'Tip plita',
  'Material placa',
  'Suprafata inferioara',
  'Suprafata superioara',
  'Productie',
  'Tip',
  'Diametru lama',
  'Grosime taiere max.',
  'Viteza',
  'Viteza spirala',
  'Viteza bol',
  'Viteza lame',
  'Pompa de vid',
  'Latime camera',
  'Adancime camera',
  'Inaltime camera',
  'Bara de etansare',
  'Dimensiune rola',
  'Tip racire',
  'Capacitate containere',
  'Model banc',
  'Montare',
  'Putere absorptie',
  'Inaltimea maxima de spalat',
  'Dimensiune cos',
  'Capacitate pahare',
  'Rebord',
  'Latime',
  'Adancime',
  'Inaltime',
  'Lungime',
  'Diametru',
  'Material',
  'Dimensiune cuva WxDxH',
  'Nr. cuve',
  'Putere convectie',
  'Putere grill',
  'Putere set',
  'Eficienta energetica',
  'Boiler',
  'Capacitate absorptie',
  'Capacitate cafea',
  'Capacitate cuptor',
  'Capacitate de incarcare',
  'Capacitate farfurii',
  'Capacitate feliere',
  'Capacitate GN',
  'Capacitate pui',
  'Capacitate set',
  'Capacitate/raft',
  'Ciclu/min',
  'Conectivitate',
  'Diametru set',
  'Dimeniune tija',
  'Dimensiune gratar',
  'Dimensiune incarcare',
  'Dimensiune max container',
  'Dimensiune vafa',
  'Dimensiunile dupa pliere WxDxH',
  'Distanta dintre tavi',
  'Forma gratar',
  'Gaz refrigerant',
  'Grilaj scrugere',
  'Grosime sigilare',
  'Inaltime set',
  'Inaltimea min. a recipietului',
  'Latime set',
  'Lungime ax',
  'Lungime brat',
  'Lungime furtun',
  'Lungime lama',
  'Lungimea sigilarii',
  'Material rezistenta',
  'Material usa',
  'Montare pe masina de spalat',
  'Nivelul de umplere',
  'Numar arzatoare',
  'Numar rafturi',
  'Orificiu debarasare',
  'Polite',
  'Portii/h',
  'Pozitie cuva',
  'Precizie',
  'Rafturi',
  'Reglaj inaltime polite',
  'Rezistente',
  'Role',
  'Sertare',
  'Suport pentru cutite',
  'Suprafata utila',
  'Tehnologie',
  'Temperatura de lucru ambientala',
  'Temperatura de prelucrare',
  'Timer',
  'Tip cuptor',
  'Tip de gatit',
  'Tip polita',
  'Tip usa',
  'Trepte viteza',
  'Umiditate',
  'Usi',
  'Voltaj',
  'Zone de incalzire',
  'Zone frigorifice',
];
